<template>
  <div
    class="contactus"
    :style="{
      height: pageWidth < 700 ? '100%' : 'calc(100vh - 310px)',
    }"
  >
    <el-backtop :visibility-height="20"> </el-backtop>
    <div class="website_header">
      <img
        src="@/assets/logo2.png"
        :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"
        alt=""
      />
      <h1
        style="margin-bottom: 52px"
        :style="{ fontSize: pageWidth < 700 ? '32px' : '64px' }"
      >
        加入社区
      </h1>
    </div>

    <div
      class="contact_content"
      :style="{
        display: pageWidth < 700 ? 'block' : 'flex',
        justifyContent: pageWidth < 700 ? '' : 'space-evenly',
      }"
    >
      <div
        class="contact_con_c"
        :style="{
          width: pageWidth < 700 ? '88%' : '25%',
        }"
      >
        <a href="https://twitter.com/CPLE120Y" target="_blank">
          <p>Twitter</p>
          <div>
            <i class="iconfont icon-birdxiaoniao"></i>
          </div>
        </a>
      </div>
      <div
        class="contact_con_c"
        :style="{
          width: pageWidth < 700 ? '88%' : '25%',
        }"
      >
        <a href="https://t.me/CNCarpoolLifeEconomy" target="_blank">
          <p>Telegram</p>
          <div>
            <i class="iconfont icon-telegram-plane"></i>
          </div>
        </a>
      </div>
      <div
        class="contact_con_c"
        :style="{
          width: pageWidth < 700 ? '88%' : '25%',
        }"
      >
        <a href="https://discord.gg/wPKjU3YW6c" target="_blank">
          <p>Discord</p>
          <div>
            <i class="iconfont icon-discord"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import "../ContactUs/index.scss";
export default {
  data() {
    return {
      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },
};
</script>
