var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactus",style:({
    height: _vm.pageWidth < 700 ? '100%' : 'calc(100vh - 310px)',
  })},[_c('el-backtop',{attrs:{"visibility-height":20}}),_c('div',{staticClass:"website_header"},[_c('img',{style:({ display: _vm.pageWidth < 700 ? 'none' : 'flex' }),attrs:{"src":require("@/assets/logo2.png"),"alt":""}}),_c('h1',{staticStyle:{"margin-bottom":"52px"},style:({ fontSize: _vm.pageWidth < 700 ? '32px' : '64px' })},[_vm._v(" 加入社区 ")])]),_c('div',{staticClass:"contact_content",style:({
      display: _vm.pageWidth < 700 ? 'block' : 'flex',
      justifyContent: _vm.pageWidth < 700 ? '' : 'space-evenly',
    })},[_c('div',{staticClass:"contact_con_c",style:({
        width: _vm.pageWidth < 700 ? '88%' : '25%',
      })},[_vm._m(0)]),_c('div',{staticClass:"contact_con_c",style:({
        width: _vm.pageWidth < 700 ? '88%' : '25%',
      })},[_vm._m(1)]),_c('div',{staticClass:"contact_con_c",style:({
        width: _vm.pageWidth < 700 ? '88%' : '25%',
      })},[_vm._m(2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://twitter.com/CPLE120Y","target":"_blank"}},[_c('p',[_vm._v("Twitter")]),_c('div',[_c('i',{staticClass:"iconfont icon-birdxiaoniao"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://t.me/CNCarpoolLifeEconomy","target":"_blank"}},[_c('p',[_vm._v("Telegram")]),_c('div',[_c('i',{staticClass:"iconfont icon-telegram-plane"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://discord.gg/wPKjU3YW6c","target":"_blank"}},[_c('p',[_vm._v("Discord")]),_c('div',[_c('i',{staticClass:"iconfont icon-discord"})])])}]

export { render, staticRenderFns }